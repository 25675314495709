(function() {
  'use strict';

  angular
    .module('menu')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('menu', {
        url: '/menu',
        views: {
          'mainView': {
            templateUrl: 'menu/menu.tpl.html',
            controller: 'MenuCtrl',
            controllerAs: 'menu'
          },
          'layoutView': {
          templateUrl: 'partials/layout.html',
          }
        }
      });
  }
}());
